import axios from 'axios'
import store from '@/store'
import qs from 'qs'
// import sign from './sign.js'
import { Toast } from 'vant'

// 线上环境请求
let baseURL = process.env.VUE_APP_BASE_URL

// loading白名单
let noLoadingArr = [
  'option/trans_data',
  'kline/index',
]
// 创建axios实例
const service = axios.create({
  baseURL,
  timeout: 200000 // 请求超时时间
  // withCredentials: true
})
// axios.defaults.headers.post['Content-Type'] = 'application/json'

// request拦截器
service.interceptors.request.use(config => {
  if(!noLoadingArr.includes(config.url)){
    store.commit('SHOW_LOADING')
  }
  // console.log(config);
  // if (config.data == undefined) { //没有权限
  //     Toast.fail(config.message)
  //     router.push({name:"login"})
  // }
  config.headers['lang'] = localStorage.getItem('lang') ? localStorage.getItem('lang'):'en'
  config.headers['Authorization'] = store.getters.Authorization || localStorage.getItem("Authorization");
  // config.headers['lang'] = localStorage.getItem('lang')?localStorage.getItem('lang'):'en'
    return config
}, error => {
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(function (response) {
  store.commit('HIDE_LOADING')
  // 对响应数据做点什么
  return response.data
    
}, function (error) {
  store.commit('HIDE_LOADING')
  // 对响应错误做点什么
  return Promise.reject(error)
});

export default service