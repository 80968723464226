import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
let token = localStorage.getItem('Authorization')
export default new Vuex.Store({
  state: {
    Authorization: token || null,
    lang: null,
    loading: false,
  },
  getters: {
    token(state) {
      return state.Authorization;
    },
    lang(state) {
      return state.lang;
    },
  },
  mutations: {
    updateDidAndToken(state, data) {
      state.Authorization = data.Authorization
      state.lang = data.lang || 'en'
      localStorage.setItem("Authorization", data.Authorization);
      localStorage.setItem("lang", data.lang);
    },
    updateDidAndlang(state, data) {
      state.lang = data.lang || 'en'
      localStorage.setItem("lang", data.lang);
    },
    SHOW_LOADING(state) {
      state.loading = true
    },
    HIDE_LOADING(state) {
      state.loading = false
    }
  },
  actions: {
  },
  modules: {
  }
})
