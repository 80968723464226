<template>
  <div id="app" :style="`padding-bottom: ${isIphonex ? '20px' : 0}`" >
    <router-view />
    <loading v-show="loading" />
  </div>
</template>

<script>
import Loading from "@/components/loading.vue";
import { mapState } from "vuex";
import {system_setting} from './api/index.js'
import { createSocket} from "@/utils/websocket";
import { createSockethome} from "@/utils/websockethome";
export default {
  name: "App",
  components: { Loading },
  data() {
    return {};
  },
  computed: {
    ...mapState(["loading"]),
    isIphonex(){
      let isIphonex = false
      if (typeof window !== 'undefined' && window) {
        isIphonex = /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
      }
      return isIphonex
    }
  },
  created(){
      createSocket();
      createSockethome()
      system_setting().then((res)=>{
        document.title = res.data.title
        let link = document.querySelector('link[rel="icon"]')
        link.href = res.data.title_ico
      })
  }

};
</script>

<style lang="scss">
</style>
