<template>
    <van-overlay>
        <div class="wrapper">
            <van-loading size="32px" vertical color="#fff">{{$t('lang.9010')}}</van-loading>
        </div>
    </van-overlay>
</template>

<script>
    export default {
        name: 'loading',    
        data() {
            return {
            };
        },
        props: {},
        methods: {},
        created() {},
    }
</script>
<style scoped>
.van-overlay{
    z-index: 9999;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>
