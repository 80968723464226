import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Tools from '@/assets/js/tools.js'
import Vant from 'vant';
import VueI18n from 'vue-i18n'
import 'vant/lib/index.css';
import md5 from 'md5';
import BigNumber from 'bignumber.js'
import echarts from 'echarts'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/global.scss'
Vue.config.productionTip = false
Vue.prototype.$md5 = md5;
Vue.prototype.$BigNumber = BigNumber
Vue.use(Vant);
Vue.use(VueI18n)
Vue.use(ElementUI);
Vue.use(Tools)
const lang = localStorage.getItem('lang') || 'en'

const i18n = new VueI18n({
  locale: lang,  // 语言标识
  fallbackLocale: 'en',
  messages: {
    'zh-CN': require('@/i18n/zh.js'),
    'en': require('@/i18n/en.js'),
  },
  silentTranslationWarn: true
})
Vue.prototype.$myI18n = i18n
Vue.prototype.$echarts = echarts;
console.log('version_2023_0106_1214');
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
