import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( '@/views/home.vue')
  },
  {
    path: '/ass',
    name: 'ass',
    component: () => import( '@/views/assetss/assets.vue')
  },
  {
    path: '/del',
    name: 'assdel',
    component: () => import( '@/views/assetss/assdel.vue')
  },
  {
    path: '/transaction',
    redirect: '/transaction/option',
    name: 'transaction',
    component: () => import( '@/views/transaction/transaction.vue'),
    children:[
      {
        path: '/transaction/encryption',
        redirect: '/transaction/encryption/contract',
        name: 'encryption',
        component: () => import( '@/views/transaction/encryption.vue'),
        children:[
          {
            path: 'contract',
            name: 'contract',
            component: () => import( '@/views/transaction/encryption/contract.vue'),
          },
          {
            path: 'Currencytransaction',
            name: 'Currencytransaction',
            component: () => import( '@/views/Currencytransaction/Currencytransaction.vue'),
          }
        ]
      },
      {
        path: '/transaction/Bulk',
        name: 'Bulk',
        component: () => import( '@/views/transaction/Bulk/Bulk.vue')
      },
      {
        path: '/transaction/foreign',
        name: 'foreign',
        component: () => import( '@/views/transaction/foreignexchange/foreign.vue')
      },
      {
        path: '/transaction/option',
        redirect: '/transaction/option/tans',
        name: 'option',
        component: () => import( '@/views/transaction/option.vue'),
        children:[
          {
            path: '/transaction/option/tans',
            name: 'tans',
            component: () => import( '@/views/transaction/option/trans.vue'),
            meta: {
              keepAlive: false
            }
          },
          {
            path: '/transaction/option/Position',
            name: 'Position',
            component: () => import( '@/views/transaction/option/Position.vue'),
            meta: {
              keepAlive: true
            }
          },
          {
            path: '/transaction/option/deal',
            name: 'deal',
            component: () => import( '@/views/transaction/option/deal.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import( '@/views/customer.vue')
  },
  {
    path: '/Withdrawal',
    name: 'Withdrawal',
    component: () => import( '@/views/assetss/Withdrawal.vue')
  },
  {
    path: '/bankcard',
    name: 'bankcard',
    component: () => import( '@/views/assetss/bankcard.vue')
  },
  {
    path: '/addbankcard',
    name: 'addbankcard',
    component: () => import( '@/views/assetss/addbankcard.vue')
  },
  {
    path: '/sussem',
    name: 'sussem',
    component: () => import( '@/views/assetss/sussem.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import( '@/views/users/user.vue'),
  },
  {
    path: '/seting',
    name: 'seting',
    component: () => import('@/views/users/seting.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '@/components/userlogin.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import( '@/views/users/personal.vue')
  },
  {
    path: '/SecurityCenter',
    name: 'SecurityCenter',
    component: () => import( '@/views/users/SecurityCenter.vue')
  },
  {
    path: '/identity',
    name: 'identity',
    component: () => import( '@/views/users/identity.vue')
  },
  {
    path: '/Primarycertification',
    name: 'Primarycertification',
    component: () => import( '@/views/users/Primarycertification.vue')
  },{
    path: '/capital',
    name: 'capital',
    component: () => import( '@/views/users/capital.vue')
  },{
    path: '/newcapital',
    name: 'newcapital',
    component: () => import( '@/views/users/newcapital.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( '@/components/register.vue')
  },
  {
    path: '/kxt',
    name: 'kxt',
    component: () => import( '@/components/kxt.vue')
  },
  {
    path: '/heyuekxt',
    name: 'heyuekxt',
    component: () => import( '@/components/heyuekxt.vue')
  },{
    path: '/dazkxt',
    name: 'dazkxt',
    component: () => import( '@/components/dazkxt.vue')
  },{
    path: '/waihkxt',
    name: 'waihkxt',
    component: () => import( '@/components/waihkxt.vue')
  },
  {
    path: '/other',
    name: 'other',
    component: () => import( '@/views/users/othen.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import( '@/views/agreement.vue')
  },
  {
    path: '/Position',
    name: 'Position',
    component: () => import( '@/views/transaction/option/Position.vue')
  },
  {
    path: '/newpassword',
    name: 'newpassword',
    component: () => import( '@/views/users/newpassword.vue')
  },
  {
    path: '/tbass',
    name: 'tbass',
    component: () => import( '@/views/tbass.vue')
  },
  {
    path: '/Transfer',
    name: 'Transfer',
    component: () => import( '@/views/Transfer.vue')
  },
  {
    path: '/VoucherCenter',
    name: 'VoucherCenter',
    component: () => import( '@/views/VoucherCenter.vue')
  },
  {
    path: '/addtbass',
    name: 'addtbass',
    component: () => import( '@/views/addtbass.vue')
  },
  {
    path: '/cashgift',
    name: 'cashgift',
    component: () => import( '@/views/cashgift/cashgiftind.vue')
  },
  {
    path: '/addiput',
    name: 'addiput',
    component: () => import( '@/views/cashgift/addiput.vue')
  },
  {
    path: '/fitsuc',
    name: 'fitsuc',
    component: () => import( '@/views/cashgift/fitsuc.vue')
  },{
    path: '/higper',
    name: 'higper',
    component: () => import( '@/views/users/higper.vue')
  }
  
  
]

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  routes
})

export default router
