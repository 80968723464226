import request from './request'

// export function estimateGas (params) {
//   return request({
//     // url: 'https://api.hecoinfo.com/api',
//     // url: 'https://api-cn.etherscan.com/api/',
//     url: 'https://api.bscscan.com/api/',
//     method: 'get',
//     params: {
//       // apikey: 'dqnh6tvdf3-4e3ed716-a4a86c4c-ea965', // 火币
//       // apikey: 'VKHG7ITK6APJBBDQQEEED18JQ3X5CMY8D4', // ETH
//       apikey: 'S61ZYEGKFS7PS8EX4EN34846MR251JCXKR', // 币安
//       ...params
//     }
//   })
// }
//轮播图
export function slidesshow (params) {
  return request({
    url: 'slides/show?type=2',
    method: 'get',
    params
  })
}
//用户信息
export function userinfo (params) {
  return request({
    url: 'user/info',
    method: 'get',
    params
  })
}
//数字合约推荐列表
export function contractsymbols (params) {
  return request({
    url: 'contract/symbols',
    method: 'get',
    params
  })
}
//K线
export function klineindex (params) {
  return request({
    url: 'kline/index',
    method: 'get',
    params
  })
}
//深度图
export function forexdepth (params) {
  return request({
    url: 'forex/depth',
    method: 'get',
    params
  })
}
//区号
export function countrycodes (params) {
  return request({
    url: 'country/codes',
    method: 'get',
    params
  })
}
//国家
export function countrycountry_code (params) {
  return request({
    url: 'country/country_code',
    method: 'get',
    params
  })
}
//客服
export function kefu_url (params) {
  return request({
    url: 'kefu_url',
    method: 'get',
    params
  })
}
//品种详情
export function contract_data (code) {
  return request({
      url: `exchange/symbols_data?code=${code}`,
      method: 'get',
  })
}
//品种详情1
export function get_code_info (code) {
  return request({
      url: `exchange/get_code_info?code=${code}`,
      method: 'get',
  })
}
//验证资金密码
export function verify_pay_pwd (data) {
  return request({
    url: 'user/verify_pay_pwd',
    method: 'post',
    data
  })
}
//划转
export function transferaction (data) {
  return request({
    url: 'transfer/action',
    method: 'post',
    data
  })
}
//外汇大宗休市状态
export function product_status (params) {
  return request({
      url: 'market/product_status',
      method: 'get',
      params
  })
}
//第三房充值渠道
export function platforms (params) {
  return request({
    url: 'fbTrade/platforms',
    method: 'get',
    params
  })
}
//添加提币地址
export function addressadd (data) {
  return request({
    url: 'address/add',
    method: 'post',
    data
  })
}
//删除提币地址
export function addressdelete (data) {
  return request({
    url: 'address/delete',
    method: 'post',
    data
  })
}
//第三房充值渠道
export function qiniu_token (params) {
  return request({
    url: 'upload/qiniu_token',
    method: 'get',
    params
  })
}
export function qiniu_upload (data) {
  return request({
    url: 'upload/images',
    method: 'post',
    data
  })
}
//高级认证
export function advanced_certification (data) {
  return request({
    url: 'authentication/advanced_certification',
    method: 'post',
    data
  })
}
export function system_setting (params) {
  return request({
    url: 'system_setting',
    method: 'get',
    params
  })
}
//关于我们
export function agreeshow (params) {
  return request({
    url: 'agree/show',
    method: 'get',
    params
  })
}



